import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';

import {
  ApiLookupLevel,
  ApiLookupSavingThrowType,
  ApiLookupClass,
  ApiLookupComponentType,
  ApiLookupEffectType,
  ApiLookupDice,
  ApiLookupRest,
  ApiLookupSpellSchoolType,
  ApiLookupRangeType,
  ApiLookupAreaType,
  ApiLookupCastingTimeType,
  ApiLookupDurationTimeType,
  ApiLookupDurationType,
  ApiLookupCopyrightHolders,
  ApiLookupTranslators,
  ApiLookupMagicItemCategoryType,
  ApiLookupMagicItemRarityType,
  ApiLookupMagicItemChargesResetType,
  ApiLookupRace,
  ApiLookupLanguageType,
  ApiLookupItemType,
} from '@api/types';

import { AppConstants } from '@core/app-constants';
import { getSpellLevelName } from '@core/helpers';

@Injectable({
  providedIn: 'root',
})
export class ApiLookupService {
  constructor(private http: HttpClient) {}

  getLevels() {
    return of<ApiLookupLevel[]>([0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(Id => ({ Id: Id, Name: getSpellLevelName(Id) })));
  }

  getClasses() {
    return this.http.get<ApiLookupClass[]>(`${AppConstants.API_URL}/lookup/classes`);
  }

  getRaces() {
    return this.http.get<ApiLookupRace[]>(`${AppConstants.API_URL}/lookup/races`);
  }

  getSavingThrowTypes() {
    return this.http.get<ApiLookupSavingThrowType[]>(`${AppConstants.API_URL}/lookup/saving-throw-types`);
  }

  getComponentTypes() {
    return this.http.get<ApiLookupComponentType[]>(`${AppConstants.API_URL}/lookup/component-types`);
  }

  getEffectTypes() {
    return this.http.get<ApiLookupEffectType[]>(`${AppConstants.API_URL}/lookup/effect-types`);
  }

  getDiceTypes() {
    return this.http.get<ApiLookupDice[]>(`${AppConstants.API_URL}/lookup/dice-types`);
  }

  getRestTypes() {
    return this.http.get<ApiLookupRest[]>(`${AppConstants.API_URL}/lookup/rest-types`);
  }

  getSpellSchoolTypes() {
    return this.http.get<ApiLookupSpellSchoolType[]>(`${AppConstants.API_URL}/lookup/spell-school-types`);
  }

  getDurationType() {
    return this.http.get<ApiLookupDurationType[]>(`${AppConstants.API_URL}/lookup/duration-types`);
  }

  getDurationTimeType() {
    return this.http.get<ApiLookupDurationTimeType[]>(`${AppConstants.API_URL}/lookup/duration-time-types`);
  }

  getCastingTimeType() {
    return this.http.get<ApiLookupCastingTimeType[]>(`${AppConstants.API_URL}/lookup/casting-time-types`);
  }

  getRangeType() {
    return this.http.get<ApiLookupRangeType[]>(`${AppConstants.API_URL}/lookup/range-types`);
  }

  getAreaType() {
    return this.http.get<ApiLookupAreaType[]>(`${AppConstants.API_URL}/lookup/area-types`);
  }

  getCopyrightHolders() {
    return this.http.get<ApiLookupCopyrightHolders[]>(`${AppConstants.API_URL}/lookup/copyright-holders`);
  }

  getTranslators() {
    return this.http.get<ApiLookupTranslators[]>(`${AppConstants.API_URL}/lookup/translators`);
  }

  getMagicItemCategoryTypes() {
    return this.http.get<ApiLookupMagicItemCategoryType[]>(`${AppConstants.API_URL}/lookup/magic-item-category-types`);
  }

  getMagicItemRarityTypes() {
    return this.http.get<ApiLookupMagicItemRarityType[]>(`${AppConstants.API_URL}/lookup/magic-item-rarity-types`);
  }

  getMagicItemResetChargesTypes() {
    return this.http.get<ApiLookupMagicItemChargesResetType[]>(
      `${AppConstants.API_URL}/lookup/magic-item-reset-charges-types`
    );
  }

  getItemTypes() {
    return this.http.get<ApiLookupItemType[]>(`${AppConstants.API_URL}/lookup/item-types`);
  }

  getLanguageTypes() {
    return this.http.get<ApiLookupLanguageType[]>(`${AppConstants.API_URL}/lookup/language-types`);
  }
}
