import { Injectable } from '@angular/core';

import { ApiService } from '@api/services';
import { ApiAdminUserListItem } from '@api/types';

import { AppArraySource } from '@core/types';

@Injectable({
  providedIn: 'root',
})
export class LookupAdminDataService {
  users = new AppArraySource<ApiAdminUserListItem>();

  constructor(private apiService: ApiService) {}

  loadData() {
    this.apiService.getAdminUsers().subscribe(data => this.users.setData(data));
  }
}
